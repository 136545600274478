import React from 'react';
import './estilos.css';
import { useState } from 'react';
import { Formulario, ContenedorBotonCentrado, Boton, MensajeError, MensajeExito, Titulo} from '../elements/Forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux'
import SEO from '../components/Commons/SEO'
import { FormEvent } from 'react'

import Input from '../components/Input'


const Signup = ():JSX.Element => {
    const dispatch = useDispatch()

    function handlesubmit(e:FormEvent){
        e.preventDefault()

        const params={
            usuario:(document.getElementById("usuario") as HTMLInputElement).value,
            nombre:(document.getElementById("nombre") as HTMLInputElement).value,
            password:(document.getElementById("password") as HTMLInputElement).value,
            password2:(document.getElementById("password2") as HTMLInputElement).value,
            correo:(document.getElementById("correo") as HTMLInputElement).value,
            telefono: (document.getElementById("telefono") as HTMLInputElement).value,
        }

        if(
            usuario.valido == 'true' && 
            nombre.valido == 'true' &&
            password.valido == 'true' &&
            password2.valido == 'true' &&
            correo.valido == 'true' &&
            telefono.valido == 'true'
        ){
            cambiarFormularioValido(true);
            cambiarUsuario({campo: '', valido: null});
            cambiarNombre({campo: '', valido: null});
            cambiarPassword({campo: '', valido: null});
            cambiarPassword2({campo: '', valido: null});
            cambiarCorreo({campo: '', valido: null});
            cambiarTelefono({campo: '', valido: null});
        }else{
            cambiarFormularioValido(false);
        }

       // dispatch(signup(params))
       console.log(usuario);
       console.log(nombre);
       console.log(password);
       console.log(password2);
       console.log(correo);
       console.log(telefono);
       
    }

    const [usuario, cambiarUsuario] = useState({campo: '', valido: null});
    const [nombre, cambiarNombre] = useState({campo: '', valido: null});
    const [password, cambiarPassword] = useState({campo: '', valido: null});
    const [password2, cambiarPassword2] = useState({campo: '', valido: null});
    const [correo, cambiarCorreo] = useState({campo: '', valido: null});
    const [telefono, cambiarTelefono] = useState({campo: '', valido: null});
    const [formularioValido, cambiarFormularioValido] = useState(null);

    const expresiones = {
        usuario: /^[a-zA-Z0-9-]{4,16}$/, // Letras, numeros, guion y guion_bajo
        nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        password: /^.{4,12}$/, // 4 a 12 digitos.
        correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        telefono: /^\d{8}$/ // 8 numeros.
    }

    const validarPassword2 = () => {
        if(password.campo.length > 0){
            if(password.campo !== password2.campo){
                cambiarPassword2((prevState) => {
                    return {...prevState, valido: 'false'}

                });
            }else{
                cambiarPassword2((prevState) => {
                    return {...prevState, valido: 'true'}

                });
            }
        }
    }
    
    return (
        <>
        <SEO title="Signup" />
        <main>
        
            <Titulo>
                Registro
            </Titulo>
            
            <Formulario action="" onSubmit={(e: FormEvent) => handlesubmit(e)}>
                <Input 
                    id = "usuario"
                    estado = {usuario}
                    cambiarEstado = {cambiarUsuario}
                    tipo = "text"
                    label = "Usuario"
                    placeholder = "Nombre de usuario"
                    name = "usuario"

                    leyendaError = "El usuario tiene que ser de 4 a 16 digitos y solo puede contener números, letras y guión bajo."
                    expresionRegular = {expresiones.usuario}
                />
                <Input 
                    id = "nombre"
                    estado = {nombre}
                    cambiarEstado = {cambiarNombre}
                    tipo = "text"
                    label = "Nombre completo"
                    placeholder = "Nombre completo"
                    name = "nombre"
                    leyendaError = "El nombre solo puede contener letras y espacios."
                    expresionRegular = {expresiones.nombre}
                />
                 <Input 
                    id="password"
                    estado = {password}
                    cambiarEstado = {cambiarPassword}
                    tipo = "password"
                    label = "Contraseña"
                    name = "password1"
                    leyendaError = "La contraseña tiene que ser de 4 a 12 dígitos."
                    expresionRegular = {expresiones.password}
                />
                  <Input 
                  id="password2"
                    estado = {password2}
                    cambiarEstado = {cambiarPassword2}
                    tipo = "password"
                    label = "Repetir contraseña"
                    name = "password2"
                    leyendaError = "Ambas contraseñas deben ser iguales."
                    funcion={validarPassword2}
                />
                <Input 
                    id="correo"
                    estado = {correo}
                    cambiarEstado = {cambiarCorreo}
                    tipo = "email"
                    label = "Correo electrónico"
                    placeholder = "john@correo.com"
                    name = "correo"
                    leyendaError = "El correo solo puede contener letras, números, puntos, guiones y gión bajo."
                    expresionRegular = {expresiones.correo}
                />
                <Input 
                    id="telefono"
                    estado = {telefono}
                    cambiarEstado = {cambiarTelefono}
                    tipo = "text"
                    label = "Teléfono"
                    placeholder = "26638506"
                    name = "telefono"
                    leyendaError = "El teléfono solo puede contener 8 números."
                    expresionRegular = {expresiones.telefono}
                />
  
               {formularioValido === false && <MensajeError>
                    <p>
                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                        <b>Error:</b> Por favor rellene el formulario correctamente.
                    </p>
                </MensajeError>}
                <ContenedorBotonCentrado>
                    <Boton type = "submit">REGISTRAR</Boton>
                   {formularioValido === true && <MensajeExito>Formulario enviado exitosamente!</MensajeExito>}
                </ContenedorBotonCentrado>
            </Formulario>
        </main>
        </>
    );

}



export default Signup;

